import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div className={`${props.show ? '' : 'hidden'} fixed z-10 inset-0 overflow-y-auto`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div onClick={() => props.toggleVideo()} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div onClick={() => props.toggleVideo()} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl xl:max-w-6xl sm:w-full sm:p-6">
                <div className="max-w-7xl mx-auto ">
                    <div className="aspect-w-16 aspect-h-9">
                        <iframe
                            src={videoSrcURL}
                            title={videoTitle}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
)
export default Video
