import React, { useState } from "react"

const CTA = (props) => {


    return (
        <div className="relative bg-blue-dark">

            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
                                <span className="block">Grade-D Fire</span>
                                <span className="block">Alarm Systems.</span>
                            </h2>
                            <p className="mt-4 text-lg leading-6 text-gray-500">A practical guide for ensuring that Grade D1/D2 fire detection and fire alarm systems are suitably designed, installed, commissioned and maintained.</p>
                            <a target="_blank" href="https://www.docs-store.co.uk/product-page/the-practical-guide-to-grade-d-fire-alarm-systems" className="mt-8 bg-blue border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-white font-medium text-indigo-600 hover:bg-indigo-50">Buy the book</a>
                            <button onClick={() => props.toggleVideo()} className="mt-8 bg-white border border-transparent rounded-md shadow ml-5 px-5 py-3 inline-flex items-center text-blue font-medium text-indigo-600 hover:bg-indigo-50">Watch Video</button>
                        </div>
                    </div>
                    <div className="-mt-6 h-full align-middle">
                        <img className="h-96 mt-10 mx-auto" src="../assets/book-cover.png" alt="Grade D Fire Alarm Systems" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CTA
