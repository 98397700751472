import React, { useState } from "react"
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import HeroHeader from "../components/heroHeader";
import CTA from "../components/cta";
import Video from "../components/video";
import mp4 from "../images/video.mp4";
import Logos from "../components/logos";

const IndexPage = ({
    data: {
        site,
        allMarkdownRemark: { edges },
    },
}) => {
    const [showVideo, setShowVideo] = useState(false)

    const toggleVideo = () => {
        console.log('toggle')
        setShowVideo(!showVideo)
    }

    return (
        <Layout>
            <Helmet>
                <title>{site.siteMetadata.title}</title>
                <meta name="description" content={site.siteMetadata.description} />
            </Helmet>
            <div className="main">
                <HeroHeader />
            </div>

            <div className="bg-white">
                <div className="relative overflow-hidden">
                    <main>
                        <div className="py-10 bg-blue sm:pt-16 lg:pt-8 lg:pb-8 lg:overflow-hidden bg-header">
                            <div className="mx-auto max-w-7xl lg:px-8">
                                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                                    <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                                        <div className="lg:py-24">
                                            <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                                <span className="block">Independent</span>
                                                <span className="pb-3 block text-white sm:pb-5">Technical Excellence</span>
                                            </h1>
                                            <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                                                We are an Electrical Engineering Consultancy providing a range of services covering the electrical installation sector.
                                            </p>
                                            <div className="mt-10 sm:mt-12">
                                                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="request" className="sm:max-w-xl sm:mx-auto lg:mx-0">
                                                    <div className="sm:flex">
                                                        <input type="hidden" name="form-name" value="request" />
                                                        <div className="min-w-0 flex-1">
                                                            <label htmlFor="email" className="sr-only">Email address</label>
                                                            <input id="email" type="email" name="email" placeholder="Enter your email" className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900" />
                                                        </div>
                                                        <div className="mt-3 sm:mt-0 sm:ml-3">
                                                            <button type="submit" className="block w-full py-3 px-4 rounded-md bg-white text-black font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900">Submit</button>
                                                        </div>
                                                    </div>
                                                    <p className="mt-3 text-sm text-gray-300 sm:mt-4">By providing your email, you agree to our <a href="#" className="font-medium text-white">terms of service</a>.</p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <CTA toggleVideo={toggleVideo} />
                            <Video show={showVideo} videoSrcURL={mp4} videoTitle="Grade-D Fire Alarm Systems Video" toggleVideo={toggleVideo} />
                            <Logos />
                        </div>

                        <div className="hidden relative bg-gray-900">
                            <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                                <img className="w-full h-full object-cover" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixqx=ltsV46Ella&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100" alt="" />
                                <div aria-hidden="true" className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"></div>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
                                <div className="md:ml-auto md:w-1/2 md:pl-10">
                                    <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                                        Award winning support
                                    </h2>
                                    <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                                        We’re here to help
                                    </p>
                                    <p className="mt-3 text-lg text-gray-300">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat quisque ut interdum tincidunt duis.
                                    </p>
                                    <div className="mt-8">
                                        <div className="inline-flex rounded-md">
                                            <a href="#" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                                                Visit the support center
                                                <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>
            </div>
        </Layout>
    );
};

export default IndexPage;
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`;
